"use client";

import Link from "next/link";
import { ChevronRightIcon } from "@radix-ui/react-icons";

import { siteConfig } from "@/config/site";
import { Badge } from "@/components/ui/badge";
import { Icons } from "@/components/icons";

const magicUIPro = [
  { title: "Home", href: "/" },
  { title: "Magic UI Pro", href: "/#pricing" },
  { title: "Magic UI Free", href: "https://magicui.design" },
  { title: "Pricing", href: "/#pricing" },
  { title: "Affiliates", href: "/affiliates", badge: "Earn 30%" },
  { title: "Roadmap", href: "https://magicui.featurebase.app" },
  { title: "FAQs", href: "/#faqs" },
  { title: "Contact", href: "mailto:support@magicui.design" },
  // { title: "Privacy Policy", href: "/privacy" },
  // { title: "Terms & Conditions", href: "/terms" },
  { title: "License Agreement", href: "/license" },
];

const templates = [
  {
    title: "SaaS",
    href: "/docs/templates/saas",
    event: "template_saas_clicked",
  },
  {
    title: "Startup",
    href: "/docs/templates/startup",
    event: "template_startup_clicked",
  },
  {
    title: "Portfolio",
    href: "/docs/templates/portfolio",
    event: "template_portfolio_clicked",
  },
  // {
  //   title: "Mobile App",
  //   href: "/docs/templates/app",
  //   disabled: true,
  //   event: "template_app_clicked",
  // },
];

const pageSections = [
  { title: "Header", href: "/docs/sections/header" },
  { title: "Hero", href: "/docs/sections/hero" },
  { title: "Social Proof Press", href: "/docs/sections/social-proof-press" },
  {
    title: "Social Proof Companies",
    href: "/docs/sections/social-proof-companies",
  },
  {
    title: "Social Proof Testimonials",
    href: "/docs/sections/social-proof-testimonials",
  },
  { title: "Stats", href: "/docs/sections/stats" },
  { title: "Features", href: "/docs/sections/features" },
  { title: "Feature Cards", href: "/docs/sections/feature-cards" },
  { title: "Pricing", href: "/docs/sections/pricing" },
  {
    title: "Call To Action",
    href: "/docs/sections/call-to-action",
  },
  { title: "FAQ", href: "/docs/sections/faq" },
  { title: "Footer", href: "/docs/sections/footer" },
];

const account = [
  { title: "Log In", href: "/login" },
  { title: "Sign Up", href: "/register" },
  // { title: "Reset Password", href: "/reset-password" },
  // { title: "Account Settings", href: "/account" },
];

const footerLinks = [
  { title: "Home", links: magicUIPro },
  { title: "Templates", links: templates },
  { title: "Page Sections", links: pageSections },
  { title: "Account", links: account },
];

export function SiteFooter() {
  return (
    <footer className="border-t">
      <div className="container flex flex-col gap-8 py-4 pt-16">
        <div className="flex flex-col gap-10 lg:flex-row lg:gap-20">
          <div className="flex flex-col gap-4 lg:w-1/3">
            <div className="flex items-center space-x-2">
              <Icons.logo className="h-8 w-8" />
              <h2 className="text-xl font-bold">{siteConfig.name}</h2>
            </div>
            <p className="text-sm text-muted-foreground">
              {siteConfig.description}
            </p>
            <div className="flex space-x-4">
              <Link
                href={siteConfig.links.twitter}
                className="text-muted-foreground hover:text-foreground"
              >
                <span className="sr-only">Twitter</span>
                <Icons.twitter className="h-5 w-5" />
              </Link>
              <Link
                href={siteConfig.links.github}
                className="text-muted-foreground hover:text-foreground"
              >
                <span className="sr-only">GitHub</span>
                <Icons.gitHub className="h-5 w-5" />
              </Link>
              <Link
                href={siteConfig.links.discord}
                className="text-muted-foreground hover:text-foreground"
              >
                <span className="sr-only">Discord</span>
                <Icons.discord className="h-5 w-5" />
              </Link>
            </div>
          </div>

          <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-4 lg:flex-1">
            {footerLinks.map((section) => (
              <div key={section.title} className="space-y-3">
                <h3 className="text-sm font-medium">{section.title}</h3>
                <ul className="space-y-2">
                  {section.links.map(
                    (item: { title: string; href: string; badge?: string }) => (
                      <li key={item.title}>
                        <Link
                          href={item.href}
                          className="text-sm group text-muted-foreground hover:text-foreground flex items-center"
                        >
                          {item.title}
                          {item.badge && (
                            <Badge variant="secondary" className="ml-2">
                              {item.badge}
                            </Badge>
                          )}

                          <ChevronRightIcon className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-all duration-300 opacity-0 group-hover:opacity-100" />
                        </Link>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="mx-auto border-t py-2 grid md:grid-cols-2 h-full justify-between w-full grid-cols-1 gap-1">
          <span className="text-sm tracking-tight text-foreground">
            Copyright © {new Date().getFullYear()}{" "}
            <Link href="/" className="cursor-pointer">
              {siteConfig.name}
            </Link>
          </span>
          {/* <ul className="flex justify-start md:justify-end text-sm tracking-tight text-foreground">
            <li className="mr-3 md:mx-4">
              <Link href="/privacy">Privacy Policy</Link>
            </li>
            <li className="mr-3 md:mx-4">
              <Link href="/terms">Terms of Service</Link>
            </li>
          </ul> */}
        </div>
      </div>
    </footer>
  );
}
